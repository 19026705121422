import {
  ClientActionFunctionArgs,
  MetaFunction,
  Outlet,
  useFetcher,
  useLocation,
  useNavigate,
  useNavigation
} from '@remix-run/react';
import { useEffect } from 'react';

import { defineAction } from '~/lib/remix/defineAction';
import { HydrateFallback } from '~/root';

export const meta: MetaFunction = () => {
  return [{ title: 'DialLink' }];
};

export const action = defineAction(() => {
  return { redirect: '/v1/onboarding' };
});

export async function clientAction({ serverAction }: ClientActionFunctionArgs) {
  try {
    const userAgent = window.navigator.userAgent;

    // Check if the user is accessing from a mobile device
    if (isMobileUserAgent(userAgent)) {
      return { redirect: '/v1/browser-check' };
    }

    // Otherwise, proceed with the server-side action (onboarding)
    const data = await serverAction<typeof action>();
    if (data instanceof Response) {
      throw data;
    }

    return data;
  } catch (error) {
    return { redirect: '/v1/inboxes/calls' };
  }
}

export default function Index() {
  const location = useLocation();
  const redirect = useNavigate();
  const navigation = useNavigation();
  const entryPoint = useFetcher<typeof action>();

  useEffect(() => {
    if (location.pathname === '/') {
      entryPoint.submit('/', { method: 'PUT' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entryPoint.data && navigation.state === 'idle') {
      redirect(entryPoint.data.redirect);
    }
  }, [entryPoint.data, navigation.state, redirect]);

  if (location.pathname === '/') {
    return <HydrateFallback />;
  }

  return <Outlet />;
}

// Helper function to detect mobile browsers using the User-Agent string
function isMobileUserAgent(userAgent: string): boolean {
  const mobileRegex =
    /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|BlackBerry|webOS/i;
  return mobileRegex.test(userAgent);
}
